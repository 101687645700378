import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {IMapboxOutput} from '../../interface/mapbox-output';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.mapbox.rootGeocoding;
  }

  searchPoi(query: string): Observable<any> {
    return this.http.get<any>(`${this.url}${query}.json?&access_token=${environment.mapbox.accessToken}`).pipe(
      map((res: IMapboxOutput) => {
        return res.features;
      })
    );
  }
}
