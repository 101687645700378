import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IRoute } from '../../interface/iroute';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  ip: string;
  urlCulturalRoute: string;
  urlMarineRoute: string;
  urlMetroRoute: string;
  url: string;

  constructor(private httpClient: HttpClient) {
    this.ip = environment.ip;
    this.urlCulturalRoute = environment.rootCulturalRoute;
    this.urlMarineRoute = environment.rootMarineRoute;
    this.urlMetroRoute = environment.routeMetroRoute;
    this.url = environment.rootRoute;
  }

  getHeaders(): any {
    const access_token: string = sessionStorage.getItem('access_token');
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token };
    return headers;
  }

  getRoutes(routeType: string): Observable<IRoute[]> {
    const headers = this.getHeaders();

    if (routeType == 'culturalRoute')
      return this.httpClient.get<IRoute[]>(this.ip + this.urlCulturalRoute, { headers: headers });

    if (routeType == 'marineRoute')
      return this.httpClient.get<IRoute[]>(this.ip + this.urlMarineRoute, { headers: headers });

    if (routeType == 'busRoute' || routeType == 'metroRoute')
      return this.httpClient.get<IRoute[]>(this.ip + this.urlMetroRoute, { headers: headers });

  }

  getRouteDetails(idRoute: number): Observable<IRoute> {
    const headers = this.getHeaders();
    const parameters: string = '?routeId=' + idRoute;
    return this.httpClient.get<IRoute>(this.ip + this.url + parameters, { headers: headers })
  }

  insertRoute(route: IRoute) {
    const headers = this.getHeaders();
    return this.httpClient.post(this.ip + this.url, route, { headers: headers });
  }

  deleteRoute(idRoute: string) {
    const headers = this.getHeaders();
    const parameters: string = '?routeId=' + idRoute;
    return this.httpClient.delete(this.ip + this.url + parameters, { headers: headers })
  }

  editRoute(route: IRoute) {
    const headers = this.getHeaders();
    return this.httpClient.put(this.ip + this.url, route, { headers: headers });
  }
}
