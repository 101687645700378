import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from '../../interface/iuser';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  ip: string;
  url: string;

  constructor(private httpClient: HttpClient) {
    this.ip = environment.ip;
    this.url = environment.rootUser;
  }

  getHeaders(): any {
    const access_token: string = sessionStorage.getItem('access_token');
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token };
    return headers;
  }

  getUsers(): Observable<IUser[]> {
    const headers = this.getHeaders();
    return this.httpClient.get<IUser[]>(this.ip + this.url + 's', { headers: headers });
  }

  getUserDetail(id: string): Observable<IUser> {
    const headers = this.getHeaders();
    const parameters: string = '?appuserId=' + id;
    return this.httpClient.get<IUser>(this.ip + this.url + parameters, { headers: headers });
  }

  generateUser(user: IUser) {
    const headers = this.getHeaders();
    return this.httpClient.post(this.ip + this.url, user, { headers: headers });
  }

  editUser(user: IUser) {
    const headers = this.getHeaders();
    return this.httpClient.put(this.ip + this.url, user, { headers: headers });
  }

  deleteUser(id: string) {
    const headers = this.getHeaders();
    const parameters: string = '?appUserId=' + id;
    return this.httpClient.delete(this.ip + this.url + parameters, { headers: headers });
  }

  changePassword(passwordObj: any) {
    const headers = this.getHeaders();
    return this.httpClient.put(this.ip + this.url + '/password/change', passwordObj, { headers: headers });
  }

  sendEmailResetPassword(email: string) {
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + environment.tokenReset };
    return this.httpClient.post(this.ip + this.url + '/password/reset/request', { Email: email }, { headers: headers });
  }

  resetPassword(resetToken: string, password: string) {
    const body = {
      resetToken: resetToken,
      password: password
    };
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + environment.tokenReset };
    return this.httpClient.post(this.ip + this.url + '/password/reset/execute', body, { headers: headers });
  }

}
