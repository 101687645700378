import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  ip: string;
  url: string;

  constructor(private httpClient: HttpClient) {
    this.ip = environment.ip;
    this.url = environment.rootAuth;
  }


  signIn(email: string, password: string): Observable<any> {
    const body = {
      email: email,
      password: password
    }

    return this.httpClient.post(this.ip + this.url, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json ' })});
  }

  userActivate(registrationToken: string): Observable<any> {
    return this.httpClient.get(this.ip + '3000/activate' + '?registrationToken=' + registrationToken, { headers: new HttpHeaders({ 'Content-Type': 'application/json ' }) });
  }
}
