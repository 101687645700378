export const environment = {
  production: false,
  // ip: 'https://visualplus.tmr.vision:',
  ip: 'https://api.visualplus.net-serv.it:',
  rootAuth: '3000/authenticate',
  rootUser: '3000/user',
  rootEmergencies: '3000/emergencywarning',
  rootEvent: '3000/event',
  rootRoute: '3000/route',
  rootPoi: '3000/poi',
  rootCategory: '3000/route/',

  rootCulturalRoute: '3002/routes',
  routeMetroRoute: '3001/routes',
  rootMarineRoute: '3003/routes',

  rootCulturalCategory: '3002/route/',
  rootMarinCategory: '3003/route/',


  rootCulturalPOI: '3002/pois',
  routeMetroPOI: '3001/pois',
  rootMarinePOI: '3003/pois',

  mapbox:
  {
    accessToken: 'pk.eyJ1IjoicnVsZXRlY2giLCJhIjoiY2sxdDFycG9sMDBsZzNoa2Jyc2syMXA5YyJ9.ZJt2xJzo0zn7KOalOoSQCQ',
    rootGeocoding: 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
  },

  tokenReset: '98oNVgtinVeLgqJzUFi2TyLHm3yAtO'
};
