import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../service/user/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { IUser } from '../../../../interface/iuser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {

  addUserForm: FormGroup;
  isLoading: boolean;

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.checkAddUserForm();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  checkAddUserForm() {
    this.addUserForm = this.formBuilder.group({
      Username: [null, Validators.required],
      Role: [null, Validators.required],
      Email: [null, Validators.required],
      PasswordHash: [null, Validators.required],
      PhoneNumber: [null],
      Notifies: [[]],
      Feedback: [[]]

    });
  }

  onSubmit() {
    if (this.addUserForm.invalid) {
      return;
    }

    const user: IUser = {
      Username : this.addUserForm.value.Username,
      Role: this.addUserForm.value.Role,
      Disabled: true,
      Email: this.addUserForm.value.Email,
      PasswordHash: this.addUserForm.value.PasswordHash,
      PhoneNumber: this.addUserForm.value.PhoneNumber,
      Notifies: this.addUserForm.value.Notifies,
      Feedback: this.addUserForm.value.Feedback
    };
    this.addUser(user);
  }


  addUser(user: IUser) {
    this.isLoading = true;
    this.userService.generateUser(user).subscribe(() => {
      this.isLoading = false;
      this.translateService.get('UserCreate_200.title').subscribe(message => {
        this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
          data: message,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      })
      
      this.closeDialog();
      }, error => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Ops, si è verificato un errore imprevisto',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      }
    );
  }



}
