import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICategory } from '../../interface/icategory';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  ip: string;
  url: string;

  constructor(private httpClient: HttpClient) {
    this.ip = environment.ip;
    this.url = environment.rootCategory;
  }

  getHeaders(): any {
    const access_token: string = sessionStorage.getItem('access_token');
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token };
    return headers;
  }

  getCategories(routeType: string): Observable<Array<ICategory>>  {
    this.url = routeType === 'culturalRoute' ? environment.rootCulturalCategory : environment.rootMarinCategory;
    const headers = this.getHeaders();
    return this.httpClient.get<Array<ICategory>>(this.ip + this.url + 'categories', { headers: headers });
  }

  insertCategory(category: ICategory) {
    const headers = this.getHeaders();
    return this.httpClient.post(this.ip + this.url + 'category', category, { headers: headers });
  }

  editCategory(category: ICategory) {
    const headers = this.getHeaders();
    return this.httpClient.put(this.ip + this.url + 'category', category, { headers: headers });
  }

  deleteCategory(categoryId: number) {
    const headers = this.getHeaders();
    return this.httpClient.delete(this.ip + this.url + 'category' + '?categoryId=' + categoryId, { headers: headers });
  }
}
