import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../service/user/user.service';
import { Observable } from 'rxjs';
import { IUser } from '../../../../interface/iuser';


@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

  user$: Observable<IUser>;

  constructor(
    private dialogRef: MatDialogRef<ProfileDialogComponent>,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    const idUser: string = sessionStorage.getItem('idUser');
    this.user$ = this.userService.getUserDetail(idUser);
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
