import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../service/user/user.service';
import { IUser } from '../../../../interface/iuser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileDialogComponent implements OnInit {

  editForm: FormGroup;
  user: IUser;

  constructor(
    private dialogRef: MatDialogRef<EditProfileDialogComponent>,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UserService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    const idUser: string = sessionStorage.getItem('idUser');
    this.userService.getUserDetail(idUser).subscribe(
      res => {
        this.user = res;
        this.checkEditForm(this.user);
      });
  }

  checkEditForm(user: IUser) {
    this.editForm = this.formBuilder.group({
      ID: [user.ID],
      Username: [user.Username],
      Role: [user.Role],
      Email: [user.Email],
      Latitude: [user.Latitude],
      Longitude: [user.Longitude],
      Radius: [user.Radius],
      PhoneNumber: [user.PhoneNumber],
      EmergencyWarning: [user.EmergencyWarning],
      Feedback: [user.Feedback],
      PasswordHash: [null],
      ConfirmPassword: [null],
      Notifies: [user.Notifies]
    });
  }

  onSubmit() {
    const user: IUser = this.editForm.value;

    if (this.editForm.value.PasswordHash !== this.editForm.value.ConfirmPassword) {
      this.editForm.get('ConfirmPassword').setErrors([{ confirmPasswordError: true }]);
      return;
    }

    if (user.PasswordHash !== null) {
      this.changePassword(user);
    }

    this.editProfileUser(user);
  }

  changePassword(user: IUser) {
    const passwordObj = { Username: user.Email, Password: user.PasswordHash };
    this.userService.changePassword(passwordObj).subscribe(res => {
    }, error => {
      console.log(error);
    });
  }

  editProfileUser(user: IUser) {
    this.userService.editUser(user).subscribe(currentUser => {
      const currentUsername: string = sessionStorage.getItem('username');

      if (user.Username !== currentUsername) {
        sessionStorage.setItem('username', currentUsername);
      }

      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: this.translateService.instant('UserEdit_200.title'),
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
    }, error => {
      this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
        data: 'Opss, si è verificato un errore imprevisto',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      console.log(error);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
