import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../../service/user/user.service';
import { AddUserDialogComponent } from '../../dialog/user/add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from '../../dialog/user/edit-user-dialog/edit-user-dialog.component';
import { DeleteUserDialogComponent } from '../../dialog/user/delete-user-dialog/delete-user-dialog.component';
import { IUser } from '../../../interface/iuser';



@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  displayedColumns: string[] = ['ID', 'Disabled', 'Username', 'Email', 'Role', 'edit', 'delete'];
  dataSource: MatTableDataSource<IUser>;

  isLoading: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private cookie: CookieService,
    private userService: UserService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.populateTable();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  populateTable() {
    this.isLoading = true;

    this.userService.getUsers().subscribe(users => {
      this.dataSource.data = users;
      this.isLoading = false;
    });
  }

  // Method that show table based options selected
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  openAddDialog() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '600px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  openEditDialog(user: IUser) {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: '600px',
      data: user,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  openDeleteDialog(idUser: string, username: string) {
    const currentUser = { id: idUser, username: username };
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: '600px',
      data: currentUser,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }





}
