import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-information-general-route',
  templateUrl: './information-general-route.component.html',
  styleUrls: ['./information-general-route.component.scss']
})
export class InformationGeneralRouteComponent implements OnInit {

  @Output() informationGeneral = new EventEmitter<any>();
  informationForm: FormGroup;
  url: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.url = this.router.url;
  }

  ngOnInit() {
    this.checkInformationForm();
  }

  checkInformationForm() {
    this.informationForm = this.formBuilder.group({
      DifficultyLevel: [null, Validators.required],
      AvgDuration: [0, Validators.required],
      OrderPosition: [0],
      IsCustom: [false],
      Enabled: [false],
    });
  }


  onSubmit() {

    if (this.url.includes('Add-Bus-Route')) {
      this.informationForm.get('DifficultyLevel').setValue('Low');
    }

    if (this.url.includes('Add-Metro-Route')) {
      this.informationForm.get('DifficultyLevel').setValue('Low');
    }

    if (this.informationForm.invalid) {
      return;
    }

    this.informationGeneral.emit(this.informationForm.value);
  }

}
