import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PoiService } from '../../../../service/poi/poi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { Ipoi } from '../../../../interface/ipoi';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-delete-poi-dialog',
  templateUrl: './delete-poi-dialog.component.html',
  styleUrls: ['./delete-poi-dialog.component.scss']
})
export class DeletePoiDialogComponent implements OnInit {

  currentPoi: Ipoi;

  constructor(
    private dialogRef: MatDialogRef<DeletePoiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private translateService: TranslateService,
    private poiService: PoiService,
    private matSnackBar: MatSnackBar
  ) {
    this.currentPoi = data;
  }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  deleteCurrentPoi() {
    this.poiService.deletePoi(this.currentPoi.ID).subscribe(() => {
      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: this.translateService.instant('poi-delete'),
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
    }, erorr => {
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Ops, si è verificato un errore imprevisto',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
    });
  }

}
