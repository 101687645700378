import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-information-edit-route',
  templateUrl: './information-edit-route.component.html',
  styleUrls: ['./information-edit-route.component.scss']
})
export class InformationEditRouteComponent implements OnInit {
  @Input() informationParent: any;
  @Output() informationGeneral = new EventEmitter<any>();

  informationForm: FormGroup;
  url: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.url = this.router.url
  }

  ngOnInit() {
    this.checkInformationForm();
   
  }


  checkInformationForm() {
    if (this.informationParent) {
      this.informationForm = this.formBuilder.group({
        DifficultyLevel: [this.informationParent.DifficultyLevel, Validators.required],
        AvgDuration: [this.informationParent.AvgDuration, Validators.required],
        OrderPosition: [this.informationParent.OrderPosition],
        IsCustom: [this.informationParent.IsCustom],
        Enabled: [this.informationParent.Enabled],
      });
    }
  }


  onSubmit() {
    if (this.url.includes('Add-Bus-Route')) {
      this.informationForm.get('DifficultyLevel').setValue('Low');
    }

    if (this.url.includes('Add-Metro-Route')) {
      this.informationForm.get('DifficultyLevel').setValue('Low');
    }


    if (this.informationForm.invalid) {
      return;
    }

    this.informationGeneral.emit(this.informationForm.value);
  }

}
