import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-language-edit-route',
  templateUrl: './language-edit-route.component.html',
  styleUrls: ['./language-edit-route.component.scss']
})
export class LanguageEditRouteComponent implements OnInit {

  @Input() localesParent: Array<any>;
  @Output() locales = new EventEmitter<any>();

  languageForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.checkLanguageForm();
    this.checkLocalesForm();
  }

  checkLanguageForm() {
    this.languageForm = this.formBuilder.group({
      language: [null],
      locales: this.formBuilder.array([])
    });
  }

  checkLocalesForm() {
    const locales = this.languageForm.get('locales') as FormArray;
    if (this.localesParent.length > 0) {
      this.localesParent.forEach((currentLocale) => {
        const locale = this.formBuilder.group({
          ID: [currentLocale.ID],
          LanguageCode: [currentLocale.LanguageCode],
          Name: [currentLocale.Name, Validators.required],
          Description: [currentLocale.Description, Validators.required]
        });
        locales.push(locale);
      });
    };
  }

  get f() { return this.languageForm.controls; }

  get localesForm() { return this.languageForm.get('locales') as FormArray; }



  onChangeLanguages(language: string) {
    const locales = this.languageForm.get('locales') as FormArray;
    const locale = this.formBuilder.group({
      ID: [0],
      LanguageCode: [language],
      Name: [null, Validators.required],
      Description: [null, Validators.required]
    });

    locales.push(locale);

  }

  deleteLocale(index: number) {
    const locales = this.languageForm.get('locales') as FormArray;
    locales.removeAt(index);
  }

  onSubmit() {
    if (this.languageForm.invalid) {
      return;
    }


    this.locales.emit(this.languageForm.value);
  }


}
