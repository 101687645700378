import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { UserService } from '../../../service/user/user.service';
import { tap, switchMap } from 'rxjs/operators';
import { SnackbarSuccessComponent } from '../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../shared/snackbar/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private enableRoute: ActivatedRoute,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    ) { }

  ngOnInit() {
    this.checkForm();
  }

  checkForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
  }

  goToSignIn() {
    this.router.navigate(['/sign-in']);
  }

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    if (this.forgotPasswordForm.value.password !== this.forgotPasswordForm.value.confirmPassword) {
      this.forgotPasswordForm.get('confirmPassword').setErrors([{confirmPasswordError: true}]);
      return;
    }

    const resetPasswordAccount$ = this.enableRoute.queryParams.pipe(
      switchMap(param =>
        this.userService.resetPassword(param.resetToken, this.forgotPasswordForm.value.password)
      )
    );


    resetPasswordAccount$.subscribe(res => {
      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: `Password resettata con successo`,
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      this.router.navigate(['sign-in']);
    }, error => {
      this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Ops, si è verificato un errore imprevisto',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        console.log(error);
    });

  }

}
