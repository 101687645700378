import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../service/auth/auth.service';
import * as jwt_decode from 'jwt-decode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '../../shared/snackbar/snackbar-error/snackbar-error.component';
import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { UserService } from '../../../service/user/user.service';
import { SnackbarSuccessComponent } from '../../shared/snackbar/snackbar-success/snackbar-success.component';
import { iif } from 'rxjs';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;
  forgotPassword: FormGroup;
  showSignInForm: boolean = true;
  showForgotForm: boolean = false;
  access_token: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private enableRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initializeForms();
    this.enableRoute.queryParams.pipe(
      mergeMap(param => iif(() => param.registrationToken, this.authService.userActivate(param.registrationToken), null))
    ).subscribe();
  }

  // Check ReactiveForm login.
  initializeForms() {

    this.signInForm = this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });

    this.forgotPassword = this.formBuilder.group({
      email: [null, Validators.required]
    });
  }

  onSubmit() {
    if (this.signInForm.invalid) {
      return;
    }

    const email: string = this.signInForm.value.email;
    const password: string = this.signInForm.value.password;

    this.signIn(email, password);
  }


  signIn(email: string, password: string) {
    this.authService.signIn(email, password).pipe(
      tap(res => {
        this.access_token = res.token;
        sessionStorage.setItem('access_token', this.access_token);
        this.decodeJWT(res.token);
      }),
      switchMap(() => this.userService.getUserDetail(sessionStorage.getItem('idUser')).pipe(
        tap(user => sessionStorage.setItem('username', user.Username))
      ))
    ).subscribe(() => { },
      error => {
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Username or Password is incorrect',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      });
  }

  decodeJWT(access_token: string) {
    const decoded = jwt_decode(access_token);

    sessionStorage.setItem('idUser', JSON.stringify(decoded.ID));
    this.checkRole(decoded.role);
  }

  checkRole(role: string) {
    switch (role) {
      case 'Admin':
        this.router.navigate(['admin']);
        break;
    }
  }

  showForgot() {
    this.showSignInForm = false;
    this.showForgotForm = true;
  }

  showSignIn() {
    this.showSignInForm = true;
    this.showForgotForm = false;
  }

  goToForgot() {
    if (this.forgotPassword.invalid) {
      return;
    }

    this.userService.sendEmailResetPassword(this.forgotPassword.value.email).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: 'We have sent a password reset email',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.router.navigate(['/forgot']);
    }, error => {
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Error sending email',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
    });
  }
}
