import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { PoiService } from '../../../service/poi/poi.service';
import { Router } from '@angular/router';
import { Ipoi } from '../../../interface/ipoi';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-atlas',
  templateUrl: './atlas.component.html',
  styleUrls: ['./atlas.component.scss']
})
export class AtlasComponent implements OnInit {

  @ViewChild('map', { static: false }) div: ElementRef;
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  poisRoute: Array<Ipoi> = [];
  geoJsonList: Array<any> = [];

  constructor(private renderer: Renderer2, private poiService: PoiService, private router: Router) { }


  ngOnInit() {
    this.getUrl();
  }

  getUrl() {
    const url: string = this.router.url;

    if (url === '/admin/route-management/Atlas') {
      this.poiService.getPois('route-management').subscribe(res => {
        this.poisRoute = res;
        this.fillGeoJsonList(this.poisRoute);
      });
    }

    if (url === '/admin/marine-route-management/Atlas') {
      this.poiService.getPois('marine-route-management').subscribe(res => {
        this.poisRoute = res;
        this.fillGeoJsonList(this.poisRoute);
      });
    }
  }


  fillGeoJsonList(poisRoute: Array<Ipoi>) {
    poisRoute.forEach((poi) => {
      const geoJson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [poi.Longitude, poi.Latitude]
        },
        properties: {
          title: poi.ID,
          description: poi.CompleteAddress,
        }
      };
      this.geoJsonList.push(geoJson);
    });

    this.createMapWithMarker(this.geoJsonList);
  }


  createMapWithMarker(geoJsonList: Array<any>) {
    (mapboxgl as typeof mapboxgl).accessToken = environment.mapbox.accessToken;

    if (navigator) {
      navigator.geolocation.getCurrentPosition(position => {

        this.map = new mapboxgl.Map({
          container: this.div.nativeElement,
          style: this.style,
          zoom: 13,
          center: [position.coords.longitude, position.coords.latitude]
        });

        geoJsonList.forEach((geoJson) => {
          const marker = this.renderer.createElement('div');
          this.renderer.addClass(marker, 'marker');
          const customMarker = new mapboxgl.Marker(marker)
            .setLngLat(geoJson.geometry.coordinates)
            .setPopup(new mapboxgl.Popup({ offset: 25 })
              .setHTML('<h3>' + geoJson.properties.title + '</h3><p>' + geoJson.properties.description + '</p>'))
            .addTo(this.map);
        });
        this.map.addControl(new mapboxgl.NavigationControl());
      });
    }
  }
}
