import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { CategoryService } from '../../../service/category/category.service';
import { error } from 'protractor';
import { ICategory } from '../../../interface/icategory';
import { TranslateService } from '@ngx-translate/core';
import { AddCategoryDialogComponent } from '../../dialog/category/add-category-dialog/add-category-dialog.component';
import { EditCategoryDialogComponent } from '../../dialog/category/edit-category-dialog/edit-category-dialog.component';
import { DeleteCategoryDialogComponent } from '../../dialog/category/delete-category-dialog/delete-category-dialog.component';
import {Router} from '@angular/router';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  displayedColumns: string[] = ['Description', 'LanguageCode', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;
  currentLanguage: string;
  isLoading: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private categoryService: CategoryService,
    private translateService: TranslateService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
    this.populateTable();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  populateTable() {
    const routeType = this.router.url.includes('marine') ? 'marineRoute' : 'culturalRoute';
    this.isLoading = true;
    this.categoryService.getCategories(routeType).subscribe(categories => {
      this.isLoading = false;
      this.dataSource.data = categories;
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  // Method that show table based options selected
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '600px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });

  }

  openEditDialog(category: ICategory) {
    const dialogRef = this.dialog.open(EditCategoryDialogComponent, {
      width: '600px',
      data: category,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  openDeleteDialog(category: ICategory) {
    const dialogRef = this.dialog.open(DeleteCategoryDialogComponent, {
      width: '600px',
      data: category,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }
}
