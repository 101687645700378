import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ipoi } from '../../interface/ipoi';
import { Observable } from 'rxjs';
import { IPoiSheet } from '../../interface/poi-sheet';
import { IPoiAttachment } from '../../interface/poi-attachment';


@Injectable({
  providedIn: 'root'
})
export class PoiService {

  ip: string
  url: string;
  rootPoi: string;

  constructor(private httpClient: HttpClient) {
    this.ip = environment.ip;
    this.url = environment.rootPoi;
  }


  getHeaders(): any {
    const access_token: string = sessionStorage.getItem('access_token');
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token };
    return headers;
  }

  getPois(route: string): Observable<Ipoi[]> {
    const headers = this.getHeaders();

    if (route == 'route-management') {
      this.rootPoi = environment.rootCulturalPOI;
      return this.httpClient.get<Ipoi[]>(this.ip + this.rootPoi, { headers: headers });
    }

    if (route == 'marine-route-management') {
      this.rootPoi = environment.rootMarinePOI;
      return this.httpClient.get<Ipoi[]>(this.ip + this.rootPoi, { headers: headers });
    }

    if (route == 'bus-route' || route == 'metro-route') {
      this.rootPoi == environment.routeMetroPOI;
      return this.httpClient.get<Ipoi[]>(this.ip + '3001/pois', { headers: headers });
    }

    if (route == 'parking' || route == 'InfoPoint')
      return this.httpClient.get<Ipoi[]>(this.ip + this.url + 's', { headers: headers })

  }

  getDetailsPoi(id: number): Observable<Ipoi> {
    const headers = this.getHeaders();
    const parameters: string = '?poiId=' + id;
    return this.httpClient.get<Ipoi>(this.ip + this.url + parameters, { headers: headers });
  }

  getEmergenciesPoi() {
    const headers = this.getHeaders();
    const poiTypes: string = 'AccessPoint,AmbulanceStation,LandingSite,MountainRescue,Hospital,Medic,Assembly';
    return this.httpClient.get<Ipoi[]>(this.ip + '3000/pois?types=' + poiTypes, { headers: headers });
  }

  insertPoi(poi: Ipoi): Observable<Ipoi> {
    const headers = this.getHeaders();
    return this.httpClient.post<Ipoi>(this.ip + this.url, poi, { headers: headers });
  }

  insertPoiAttachment(attachmentData: IPoiAttachment, poiId: string, attachmentFile: any) {
    const access_token: string = sessionStorage.getItem('access_token');
    let headers = { 'Authorization': 'Bearer ' + access_token };
    const formData = new FormData();
    formData.append('attachmentData', JSON.stringify(attachmentData));
    formData.append('poiId', poiId);
    formData.append('attachmentFile', attachmentFile);
    return this.httpClient.post<Ipoi>(this.ip + this.url + '/attachment', formData, { headers: headers });
  }

  editPoi(poi: Ipoi): Observable<Ipoi> {
    const headers = this.getHeaders();
    return this.httpClient.put<Ipoi>(this.ip + this.url, poi, { headers: headers });
  }

  deletePoi(ID) {
    const headers = this.getHeaders();
    const parameters = '?poiId=' + ID;
    return this.httpClient.delete(this.ip + this.url + parameters, { headers: headers });
  }

}
