import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './component/core/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './component/core/forgot-password/forgot-password.component';
import { ResetPasswordGuard } from './guard/reset-password.guard';
import { AuthGuard } from './guard/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
    canActivate: [ResetPasswordGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('../app/module/admin/admin.module').then(module => module.AdminModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
