import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from '../../../../service/category/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { ICategory } from '../../../../interface/icategory';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-category-dialog',
  templateUrl: './delete-category-dialog.component.html',
  styleUrls: ['./delete-category-dialog.component.scss']
})
export class DeleteCategoryDialogComponent implements OnInit {
  currentCategory: ICategory;
  currentLanguage: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private categoryService: CategoryService,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar
  ) {
    this.currentCategory = data;
  }

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteCategory() {
    this.categoryService.deleteCategory(this.currentCategory.ID).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: this.translateService.instant('category-delete'),
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
    }, error => {
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Ops, si è verificato un errore imprevisto',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
    });
  }

}
