import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { ProfileDialogComponent } from '../../dialog/profile/profile-dialog/profile-dialog.component';
import { EditProfileDialogComponent } from '../../dialog/profile/edit-profile-dialog/edit-profile-dialog.component';
import { UserService } from '../../../service/user/user.service';
import { IUser } from '../../../interface/iuser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  user$: Observable<IUser>;
  username: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    translate.addLangs(['gb', 'it']);
    translate.setDefaultLang('gb');

    const browserLang = translate.getBrowserLang();
    const currentLanguage = sessionStorage.getItem('language');

    if (currentLanguage) {
      translate.use(currentLanguage);
    } else {
      translate.use(browserLang.match(/gb|it/) ? browserLang : 'gb');
    }
  }

  currentUrl: string;


  ngOnInit() {
    this.getUser();
    this.getRouterLink();
  }

  getRouterLink() {
    const url = this.router.url;

    if (url.includes('route-management')) {
      this.currentUrl = 'route-management';
    }

    if (url.includes('marine-route-management')) {
      this.currentUrl = 'marine-route-management';
    }

    if (url.includes('linee-management')) {
      this.currentUrl = 'linee-management';
    }

    if (url.includes('emergencies')) {
      this.currentUrl = 'emergencies';
    }
  }

  getUser() {
    this.username = sessionStorage.getItem('username');
  }

  // Method that change language.
  switchLanguage(language: string) {
    this.translate.use(language);
    sessionStorage.setItem('language', language);
  }

  // Methot that signOut and deleted cookie from browser.
  signOut() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('idUser');
    this.router.navigate(['sign-in']);
  }

  opentProfileDialog() {
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => console.log('Closed'));
  }


  openEditProfileDialog() {
    const dialogRef = this.dialog.open(EditProfileDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => console.log('Closed'));
  }

}
