import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';


import { SidebarComponent } from '../../component/shared/sidebar/sidebar.component';
import { ToolbarComponent } from '../../component/shared/toolbar/toolbar.component';
import { AtlasComponent } from '../../component/shared/atlas/atlas.component';
import { UserManagementComponent } from '../../component/shared/user-management/user-management.component';
import { CategoryComponent } from '../../component/shared/category/category.component';
import { SpinnerComponent } from '../../component/shared/spinner/spinner.component';

import { ProfileDialogComponent } from '../../component/dialog/profile/profile-dialog/profile-dialog.component';
import { EditProfileDialogComponent } from '../../component/dialog/profile/edit-profile-dialog/edit-profile-dialog.component';

import { AddUserDialogComponent } from '../../component/dialog/user/add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from '../../component/dialog/user/edit-user-dialog/edit-user-dialog.component';
import { DeleteUserDialogComponent } from '../../component/dialog/user/delete-user-dialog/delete-user-dialog.component';

import { LanguageRouteComponent } from '../../component/shared/language-route/language-route.component';
import { InformationGeneralRouteComponent } from '../../component/shared/information-general-route/information-general-route.component';
import { POIRouteComponent } from '../../component/shared/poi-route/poi-route.component';

import { LanguageEditRouteComponent } from '../../component/shared/language-edit-route/language-edit-route.component';
import { InformationEditRouteComponent } from '../../component/shared/information-edit-route/information-edit-route.component';
import { PoiEditRouteComponent } from '../../component/shared/poi-edit-route/poi-edit-route.component';

import { DeleteRouteDialogComponent } from '../../component/dialog/route/delete-route-dialog/delete-route-dialog.component';

import { AddPoiDialogComponent } from '../../component/dialog/POI/add-poi-dialog/add-poi-dialog.component';
import { EditPoiDialogComponent } from '../../component/dialog/POI/edit-poi-dialog/edit-poi-dialog.component';
import { DeletePoiDialogComponent } from '../../component/dialog/POI/delete-poi-dialog/delete-poi-dialog.component';

import { AddCategoryDialogComponent } from '../../component/dialog/category/add-category-dialog/add-category-dialog.component';
import { EditCategoryDialogComponent } from '../../component/dialog/category/edit-category-dialog/edit-category-dialog.component';
import { DeleteCategoryDialogComponent } from '../../component/dialog/category/delete-category-dialog/delete-category-dialog.component';




import { SnackbarSuccessComponent } from '../../component/shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../component/shared/snackbar/snackbar-error/snackbar-error.component';
import {DndDirective} from '../../directive/dnd.directive';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SidebarComponent,
    ToolbarComponent,
    UserManagementComponent,
    AtlasComponent,
    CategoryComponent,
    SpinnerComponent,
    ProfileDialogComponent,
    EditProfileDialogComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    DeleteUserDialogComponent,
    LanguageRouteComponent,
    InformationGeneralRouteComponent,
    POIRouteComponent,
    LanguageEditRouteComponent,
    InformationEditRouteComponent,
    PoiEditRouteComponent,
    AddPoiDialogComponent,
    EditPoiDialogComponent,
    DeletePoiDialogComponent,
    DeleteRouteDialogComponent,
    AddCategoryDialogComponent,
    EditCategoryDialogComponent,
    DeleteCategoryDialogComponent,
    SnackbarSuccessComponent,
    SnackbarErrorComponent,
    DndDirective
  ],

  imports: [
    CommonModule,
    HttpClientModule,
    AngularMaterialModule,
    AngularMaterialModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],

  exports: [
    SidebarComponent,
    ToolbarComponent,
    SpinnerComponent,
    LanguageRouteComponent,
    InformationGeneralRouteComponent,
    POIRouteComponent,
    LanguageEditRouteComponent,
    InformationEditRouteComponent,
    PoiEditRouteComponent,
    DndDirective
  ],
  entryComponents: [
    ProfileDialogComponent,
    EditProfileDialogComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    DeleteUserDialogComponent,

    AddPoiDialogComponent,
    EditPoiDialogComponent,
    DeletePoiDialogComponent,
    DeleteRouteDialogComponent,

    AddCategoryDialogComponent,
    EditCategoryDialogComponent,
    DeleteCategoryDialogComponent,

    SnackbarSuccessComponent,
    SnackbarErrorComponent
  ]
})
export class SharedComponentModule { }
