import { Component, OnInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PoiService } from '../../../service/poi/poi.service';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { AddPoiDialogComponent } from '../../dialog/POI/add-poi-dialog/add-poi-dialog.component';
import { Ipoi } from '../../../interface/ipoi';
import { EditPoiDialogComponent } from '../../dialog/POI/edit-poi-dialog/edit-poi-dialog.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-poi-route',
  templateUrl: './poi-route.component.html',
  styleUrls: ['./poi-route.component.scss']
})
export class POIRouteComponent implements OnInit {

  @Output() POI = new EventEmitter<any>();
  @ViewChild('map', { static: false }) div: ElementRef;
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  routeComponent: string;
  currentPOIS: Array<Ipoi>;
  displayedColumns: string[] = ['ID', 'CompleteAddress', 'Longitude', 'Latitude', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;
  geoJsonList: Array<any> = [];
  poisSelected: Array<Ipoi> = [];
  url: string;

  constructor(private router: Router, private POIService: PoiService, private dialog: MatDialog, private renderer: Renderer2) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.getRoute();
  }


  getRoute() {
    this.url =  this.router.url;

    if (this.url === '/admin/route-management/Add-Route') {
      this.getPois('route-management');
    }

    if (this.url === '/admin/marine-route-management/Add-Marine-Route') {
      this.getPois('marine-route-management');
    }

    if (this.url === '/admin/linee-management/Add-Bus-Route') {
      this.getPois('bus-route');
    }

    if (this.url === '/admin/linee-management/Add-Metro-Route') {
      this.getPois('metro-route');
    }
  }


  getPois(route: string) {
    this.routeComponent = route;
    if (route === 'bus-route' || route === 'metro-route') {
      this.filterPois(route);
    } else  {
      this.POIService.getPois(route).subscribe((res) => {
        this.currentPOIS = res;

        if (this.currentPOIS.length === 0) {
          this.createMap();
        } else {
          this.FillGeoJsonList();
          this.createMapWithMarker();
        }

      });
    }
  }

  filterPois(route: string) {
    let poiType: string;

    if (route === 'bus-route') {
      poiType = 'BusStop';
    } else {
      poiType = 'MetroStation';
    }

    const filterPois = this.POIService.getPois(this.routeComponent).pipe(
      map(pois => pois.filter(poi => poi.POIType === poiType))
    );

    filterPois.subscribe(res => {
      this.currentPOIS = res;

      if (this.currentPOIS.length === 0) {
        this.createMap();
      } else {
        this.FillGeoJsonList();
        this.createMapWithMarker();
      }
    });

  }


  createMap() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(res => {
        (mapboxgl as typeof mapboxgl).accessToken = environment.mapbox.accessToken;

        this.map = new mapboxgl.Map({
          container: this.div.nativeElement,
          style: this.style,
          zoom: 13,
          center: [res.coords.longitude, res.coords.latitude]
        });
        this.map.addControl(new mapboxgl.NavigationControl());
      });
    }
  }


  FillGeoJsonList() {
    this.currentPOIS.forEach((poi) => {
      const geoJson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [poi.Longitude, poi.Latitude]
        },
        properties: {
          ID: poi.ID,
          title: poi.POIType,
          description: poi.CompleteAddress,
          Locales: poi.Locales,
          Sheet: poi.Sheet
        }
      };
      this.geoJsonList.push(geoJson);
    });
  }


  createMapWithMarker() {
    (mapboxgl as typeof mapboxgl).accessToken = environment.mapbox.accessToken;

    this.map = new mapboxgl.Map({
      container: this.div.nativeElement,
      style: this.style,
      zoom: 13,
      center: [this.currentPOIS[0].Longitude, this.currentPOIS[0].Latitude]
    });

    this.geoJsonList.forEach((geoJson) => {
      const marker = this.renderer.createElement('div');
      this.renderer.addClass(marker, 'marker');
      const customMarker = new mapboxgl.Marker(marker)
        .setLngLat(geoJson.geometry.coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 25 })
          .setHTML('<h3>' + geoJson.properties.title + '</h3><p>' + geoJson.properties.description + '</p>'))
        .addTo(this.map);

      customMarker.getElement().addEventListener('click', () => {
        const poi: Ipoi = {
          ID: geoJson.properties.ID,
          POIType: geoJson.properties.title,
          CompleteAddress: geoJson.properties.description,
          Locales: geoJson.properties.Locales,
          Sheet: geoJson.properties.Sheet,
          Longitude: customMarker.getLngLat().lng,
          Latitude: customMarker.getLngLat().lat
        };
        if (this.poisSelected.length == 0) {
          this.poisSelected.push(poi);
          this.dataSource.data = this.poisSelected;
        } else {
          this.checkIfPoiExists(poi);
        }

      });
    });

    this.map.addControl(new mapboxgl.NavigationControl());
  }

  checkIfPoiExists(currentPoi: Ipoi) {
    let find: boolean;

    this.poisSelected.forEach((poi) => {
      if (poi.ID === currentPoi.ID) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.poisSelected.push(currentPoi);
      this.dataSource.data = this.poisSelected;
    }
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddPoiDialogComponent, {
      data: this.routeComponent,
      width: '800px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: Ipoi) => {
      if (result) {
        this.getRoute();
        this.poisSelected.push(result);
        this.dataSource.data = this.poisSelected;
      }

    });

  }

  openEditDialog(id: string) {
    const data = { route: this.routeComponent, id };
    const dialogRef = this.dialog.open(EditPoiDialogComponent, {
      data,
      width: '800px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: Ipoi) => {
      if (result) {
        this.getRoute();

        this.poisSelected.forEach((poi, index) => {
          if (poi.ID === result.ID) {
            this.poisSelected.splice(index, 1);
          }
        });

        this.poisSelected.push(result);
        this.dataSource.data = this.poisSelected;
      }
    });

  }

  removePoi(index: number) {
    this.poisSelected.splice(index, 1);
    this.dataSource.data = this.poisSelected;
  }


  saveRoute() {
    const poisStepper = {
      StartPOI: this.dataSource.data[0],
      EndPOI: this.dataSource.data[this.dataSource.data.length - 1],
      POIS: this.dataSource.data
    };

    this.POI.emit(poisStepper);
  }

}
