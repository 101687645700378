import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CategoryService } from '../../../../service/category/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { ICategory } from '../../../../interface/icategory';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.scss']
})
export class AddCategoryDialogComponent implements OnInit {

  addCategoryForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private categoryService: CategoryService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.checkCategoryForm();
  }

  checkCategoryForm() {
    this.addCategoryForm = this.formBuilder.group({
      language: [null, Validators.required],
      Locales: this.formBuilder.array([])
    });
  }

  get localesForm() { return this.addCategoryForm.get('Locales') as FormArray; }


  onChangeLanguages(language: string) {
    const locales = this.addCategoryForm.get('Locales') as FormArray;
    const locale = this.formBuilder.group({
      LanguageCode: [language],
      Description: [null, Validators.required]
    });

    if (locales.controls.length == 0) {
      locales.push(locale);
    }

    else {
      this.checkIfLocalePoiExist(locale);
    }

  }

  checkIfLocalePoiExist(locale) {
    const locales = this.addCategoryForm.get('Locales') as FormArray;
    let find: boolean;
    locales.controls.forEach((element) => {
      if (locale.value.LanguageCode === element.get('LanguageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      locales.push(locale);
    }
  }

  deleteLocale(index: number) {
    const locales = this.addCategoryForm.get('Locales') as FormArray;
    locales.removeAt(index);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.addCategoryForm.invalid) {
      return;
    }

    const category: ICategory = {
      Locales : this.addCategoryForm.value.Locales
    };
    this.insertCategory(category);
  }

  insertCategory(category: ICategory) {
    this.categoryService.insertCategory(category).subscribe(currentPoi => {
      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: this.translateService.instant('category-success'),
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
    }, error => {
      this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
        data: 'Ops, si è verificato un errore imprevisto',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      console.log(error);
    });
  }

}
