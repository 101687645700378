import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { RouteService } from '../../../../service/route/route.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-delete-route-dialog',
  templateUrl: './delete-route-dialog.component.html',
  styleUrls: ['./delete-route-dialog.component.scss']
})
export class DeleteRouteDialogComponent implements OnInit {

  idRoute: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteRouteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private cookie: CookieService,
    private translateService: TranslateService,
    private routeService: RouteService,
    private matSnackBar: MatSnackBar
  ) {
    this.idRoute = data;
  }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  deleteRoute() {
    this.routeService.deleteRoute(this.idRoute)
      .subscribe(
        () => {
          this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
            data: this.translateService.instant('route-delete'),
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
          });
          this.closeDialog();
        },
        error => {
          this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
            data: 'Ops, si è verificato un errore',
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
          });
        }
      );
  }

}
