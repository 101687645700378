import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../../service/user/user.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.scss']
})
export class DeleteUserDialogComponent implements OnInit {

  currentUser: any;
  isLoading: boolean;

  constructor(
    private dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.currentUser = data;
  }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  deleteUser() {
    this.isLoading = true;
    this.userService.deleteUser(this.currentUser.id).subscribe(res => {
      this.isLoading = false;

      this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
        data: this.translateService.instant('UserDelete_200.title'),
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
      }, error => {
        this.isLoading = false;
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
            data: 'Ops, si è verificato un errore',
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
      });
  }

}
