import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';


@Component({
  selector: 'app-snackbar-success',
  templateUrl: './snackbar-success.component.html',
  styleUrls: ['./snackbar-success.component.scss']
})
export class SnackbarSuccessComponent implements OnInit {

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarSuccessComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }


  ngOnInit() {
  }

}
