import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PoiService } from '../../../../service/poi/poi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { Ipoi } from '../../../../interface/ipoi';
import {IFeature} from '../../../../interface/feature';
import {MapboxService} from '../../../../service/mapbox/mapbox.service';
import { IPoiSheet } from '../../../../interface/poi-sheet';
import { IPoiAttachment } from '../../../../interface/poi-attachment';
import { switchMap, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-edit-poi-dialog',
  templateUrl: './edit-poi-dialog.component.html',
  styleUrls: ['./edit-poi-dialog.component.scss']
})
export class EditPoiDialogComponent implements OnInit {
  isLoading: boolean;

  routeComponent: string;
  idPoi: number;

  addPoiFormIT: FormGroup;
  addPoiFormEN: FormGroup;

  feature: IFeature;
  feautures: Array<IFeature> = [];
  currentPoi: Ipoi;

  poiSheet: IPoiSheet;

  poiAttachements: IPoiAttachment[];

  poiAttachementImage: IPoiAttachment;
  poiAttachmentsImage: IPoiAttachment[] = [];

  poiAttachementVideo: IPoiAttachment;
  poiAttachmentsVideo: IPoiAttachment[] = [];

  poiAttachementAudio: IPoiAttachment;
  poiAttachmentsAudio: IPoiAttachment[] = [];

  poiAttachementAr: IPoiAttachment;
  poiAttachmentsAr: IPoiAttachment[] = [];

  fileMap = new Map<string, File>();

  fileImage: any;
  fileVideo: any;
  fileAudio: any;
  fileAr: any;


  constructor(
    private dialogRef: MatDialogRef<EditPoiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private mapboxService: MapboxService,
    private poiService: PoiService,
    private matSnackBar: MatSnackBar
  ) {
    this.routeComponent = data.route;
    this.idPoi = data.id;
  }

  ngOnInit() {
    this.getCurrentPoi();
  }

  closeDialog() {
    this.dialogRef.close();
  }


  getCurrentPoi() {
    this.poiService.getDetailsPoi(this.idPoi).subscribe(res => {
      this.currentPoi = res;
      this.checkAddForm();
    });
  }

  checkAddForm() {
    let sheetLocaleIT: Array<any> = [];
    let sheetLocaleEN: Array<any> = [];

    if (this.currentPoi.Sheet && this.currentPoi.Sheet.POISheetLocale.length) {
      this.poiSheet = {
        ID: this.currentPoi.Sheet.ID,
        URL: this.currentPoi.Sheet.URL,
        POISheetLocale: this.currentPoi.Sheet.POISheetLocale
      };
      sheetLocaleIT = this.currentPoi.Sheet.POISheetLocale.filter(locale => locale.LanguageCode === 'IT');
      sheetLocaleEN = this.currentPoi.Sheet.POISheetLocale.filter(locale => locale.LanguageCode === 'EN');
    }

    this.addPoiFormIT = this.formBuilder.group({
      Longitude: [this.currentPoi.Longitude, Validators.required],
      Latitude: [this.currentPoi.Latitude, Validators.required],
      CompleteAddress: [this.currentPoi.CompleteAddress, Validators.required],
      POIType: [this.currentPoi.POIType],
      url: [this.currentPoi.Sheet ? this.currentPoi.Sheet.URL : null],
      title: [sheetLocaleIT.length ? sheetLocaleIT[0].Title : null],
      description: [sheetLocaleIT.length ? sheetLocaleIT[0].Description : null]
    });
    this.addPoiFormEN = this.formBuilder.group({
      Longitude: [this.currentPoi.Longitude, Validators.required],
      Latitude: [this.currentPoi.Latitude, Validators.required],
      CompleteAddress: [this.currentPoi.CompleteAddress, Validators.required],
      POIType: [this.currentPoi.POIType],
      url: [this.currentPoi.Sheet ? this.currentPoi.Sheet.URL : null],
      title: [sheetLocaleEN.length ? sheetLocaleEN[0].Title : null],
      description: [sheetLocaleEN.length ? sheetLocaleEN[0].Description : null]
    });

    if (this.currentPoi.Sheet) {
      this.filledPoiAttachmentObj();
    }

  }

  filledPoiAttachmentObj() {
    this.currentPoi.Sheet.Attachments.forEach(attachment => {
      switch (attachment.MediaRef.MediaType) {
        case 'Image': {
          this.poiAttachementImage = {
            ID: attachment.ID,
            MediaRef: {
              ID: attachment.MediaRef.ID,
              FileName: attachment.MediaRef.FileName,
              Extension: attachment.MediaRef.Extension,
              UrlPath: attachment.MediaRef.UrlPath,
              MediaType: attachment.MediaRef.MediaType,
              Locales: attachment.MediaRef.Locales
            }
          };
          this.poiAttachmentsImage.push(this.poiAttachementImage);
          break;
        }
        case 'Video': {
          this.poiAttachementVideo = {
            ID: attachment.ID,
            MediaRef: {
              ID: attachment.MediaRef.ID,
              FileName: attachment.MediaRef.FileName,
              Extension: attachment.MediaRef.Extension,
              UrlPath: attachment.MediaRef.UrlPath,
              MediaType: attachment.MediaRef.MediaType,
              Locales: attachment.MediaRef.Locales
            }
          };
          this.poiAttachmentsVideo.push(this.poiAttachementVideo);
          break;
        }
        case 'Audio': {
          this.poiAttachementAudio = {
            ID: attachment.ID,
            MediaRef: {
              ID: attachment.MediaRef.ID,
              FileName: attachment.MediaRef.FileName,
              Extension: attachment.MediaRef.Extension,
              UrlPath: attachment.MediaRef.UrlPath,
              MediaType: attachment.MediaRef.MediaType,
              Locales: attachment.MediaRef.Locales
            }
          };
          this.poiAttachmentsAudio.push(this.poiAttachementAudio);
          break;
        }
        case 'AR': {
          this.poiAttachementAr = {
            ID: attachment.ID,
            MediaRef: {
              ID: attachment.MediaRef.ID,
              FileName: attachment.MediaRef.FileName,
              Extension: attachment.MediaRef.Extension,
              UrlPath: attachment.MediaRef.UrlPath,
              MediaType: attachment.MediaRef.MediaType,
              Locales: attachment.MediaRef.Locales
            }
          };
          this.poiAttachmentsAr.push(this.poiAttachementAr);
          break;
        }
      }
    });
  }

  getCurrentAttachmentDescription(locales: Array<any>, languageCode: string): string {
    const currentLanguageLocales = locales.filter(locale => locale.LanguageCode === languageCode);
    return currentLanguageLocales.length ? currentLanguageLocales[0].Description : null;
  }

  search(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm && searchTerm.length >= 3) {
      this.feature = null;
      this.mapboxService.searchPoi(searchTerm).subscribe(res => {
        this.feautures = [];
        res.map(feature => {
          this.feautures.push({
            place_name: feature.place_name,
            city: '',
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0]
          });
        });
      });
    } else {
      this.feautures = [];
    }
  }

  addressesSelected(optionSelected: string) {
    const address: string = optionSelected;
    this.feautures.forEach(feature => {
      if (feature.place_name === address) {
        this.feature = feature;
      }
    });
    this.addPoiFormIT.get('CompleteAddress').setValue(this.feature.place_name);
    this.addPoiFormEN.get('CompleteAddress').setValue(this.feature.place_name);
    this.addPoiFormIT.get('Latitude').setValue(this.feature.latitude);
    this.addPoiFormIT.get('Longitude').setValue(this.feature.longitude);
    this.addPoiFormEN.get('Latitude').setValue(this.feature.latitude);
    this.addPoiFormEN.get('Longitude').setValue(this.feature.longitude);
  }

  inputUrlChange(value: string, language: string) {
    if (language === 'IT') {
      this.addPoiFormEN.get('url').setValue(value);
    } else {
      this.addPoiFormIT.get('url').setValue(value);
    }

  }

  onFileDropped(files: Array<any>, type: string) {
    for (const file of files) {
      this.buildPoiAttachementtObj(file, type);
    }
  }

  fileChangeEvent(event: any, type: string) {
    let file: any = null;
    if (event.target.files && event.target.files[0]) {
      file = event.target.files[0];
      this.buildPoiAttachementtObj(file, type);
    }
  }

  buildPoiAttachementtObj(file: any, type: string) {
    const fileType = file.type.split('/');
    this.fileMap.set(file.name, file);
    switch (type) {
      case 'image': {
        this.fileImage = file;
        this.poiAttachementImage = {
          ID: 0,
          MediaRef: {
            ID: 0,
            FileName: this.fileImage.name,
            Extension: fileType[1],
            UrlPath: this.fileImage.name,
            MediaType: 'Image',
            Locales: []
          }
        };
        this.poiAttachmentsImage.push(this.poiAttachementImage);
        break;
      }

      case 'audio': {
        this.fileAudio = file;
        this.poiAttachementAudio = {
          ID: 0,
          MediaRef: {
            ID: 0,
            FileName: this.fileAudio.name,
            Extension: fileType[1],
            UrlPath: this.fileAudio.name,
            MediaType: 'Audio',
            Locales: []
          }
        };
        this.poiAttachmentsAudio.push(this.poiAttachementAudio);
        break;
      }

      case 'video': {
        this.fileVideo = file;
        this.poiAttachementVideo = {
          ID: 0,
          MediaRef: {
            ID: 0,
            FileName: this.fileVideo.name,
            Extension: fileType[1],
            UrlPath: this.fileVideo.name,
            MediaType: 'Video',
            Locales: []
          }
        };
        this.poiAttachmentsVideo.push(this.poiAttachementVideo);
        break;
      }

      case 'ar': {
        this.fileAr = file;
        this.poiAttachementAr = {
          ID: 0,
          MediaRef: {
            ID: 0,
            FileName: this.fileAr.name,
            Extension: fileType[1],
            UrlPath: this.fileAr.name,
            MediaType: 'AR',
            Locales: []
          }
        };
        this.poiAttachmentsAr.push(this.poiAttachementAr);
        break;
      }
    }
  }

  descriptionFileChange(value: string, type: string, language: string, indexAttachment: number) {
    switch (type) {
      case 'image': {
        this.poiAttachmentsImage[indexAttachment].MediaRef.Locales.length
          ? this.upsertPoiAttachmentLocales(this.poiAttachmentsImage[indexAttachment], language, value)
          : this.poiAttachmentsImage[indexAttachment].MediaRef.Locales.push({
            ID: 0,
            Description: value,
            LanguageCode: language
          });
        break;
      }

      case 'audio': {
        this.poiAttachmentsAudio[indexAttachment].MediaRef.Locales.length
          ? this.upsertPoiAttachmentLocales(this.poiAttachmentsAudio[indexAttachment], language, value)
          : this.poiAttachmentsAudio[indexAttachment].MediaRef.Locales.push({
            ID: 0,
            Description: value,
            LanguageCode: language
          });
        break;
      }

      case 'video': {
        this.poiAttachmentsVideo[indexAttachment].MediaRef.Locales.length
          ? this.upsertPoiAttachmentLocales(this.poiAttachmentsVideo[indexAttachment], language, value)
          : this.poiAttachmentsVideo[indexAttachment].MediaRef.Locales.push({
            ID: 0,
            Description: value,
            LanguageCode: language
          });
        break;
      }

      case 'ar': {
        this.poiAttachmentsAr[indexAttachment].MediaRef.Locales.length
          ? this.upsertPoiAttachmentLocales(this.poiAttachmentsAr[indexAttachment], language, value)
          : this.poiAttachmentsAr[indexAttachment].MediaRef.Locales.push({
            ID: 0,
            Description: value,
            LanguageCode: language
          });
        break;
      }
    }
  }

  upsertPoiAttachmentLocales(poiAttachment: IPoiAttachment, language: string, value: string) {
    const currentLocale = poiAttachment.MediaRef.Locales.filter(locale => locale.LanguageCode === language);
    if (currentLocale.length) {
      const index = poiAttachment.MediaRef.Locales.findIndex(locale => locale.ID === currentLocale[0].ID);
      poiAttachment.MediaRef.Locales[index].Description = value;
    } else {
      poiAttachment.MediaRef.Locales.push({
        ID: 0,
        Description: value,
        LanguageCode: language
      });
    }
  }

  removeFile(type: string, indexAttachment: number, fileName: string) {
    this.fileMap.delete(fileName);
    switch (type) {
      case 'image': {
        this.poiAttachmentsImage.splice(indexAttachment, 1);
        break;
      }

      case 'audio': {
        this.poiAttachmentsAudio.splice(indexAttachment, 1);
        break;
      }

      case 'video': {
        this.poiAttachmentsVideo.splice(indexAttachment, 1);
        break;
      }

      case 'ar': {
        this.poiAttachmentsAr.splice(indexAttachment, 1);
        break;
      }
    }
  }

  onSubmit() {
    this.poiAttachements = [];

    if (this.addPoiFormIT.invalid || this.addPoiFormEN.invalid) {
      return;
    }
    const itFormValue = this.addPoiFormIT.value;
    const enFormValue = this.addPoiFormEN.value;

    if (itFormValue.url) {
      if (this.poiSheet) {
        this.poiSheet.URL = itFormValue.url;
      } else {
        this.poiSheet = {
          ID: 0,
          URL: itFormValue.url,
          POISheetLocale: [],
          Attachment: null,
          Attachments: []
        };
      }

    }

    if (itFormValue.title || itFormValue.description) {
      if (this.poiSheet) {
        if (this.poiSheet.POISheetLocale.length) {
          const currentLocale = this.poiSheet.POISheetLocale.filter(locale => locale.LanguageCode === 'IT');
          if (currentLocale.length) {
            const indexLocale = this.poiSheet.POISheetLocale.findIndex(locale => locale.LanguageCode === 'IT');
            this.poiSheet.POISheetLocale[indexLocale].Title = itFormValue.title;
            this.poiSheet.POISheetLocale[indexLocale].Description = itFormValue.description;
          } else {
            this.poiSheet.POISheetLocale.push({
              Title: itFormValue.title,
              Description: itFormValue.description,
              LanguageCode: 'IT'
            });
          }

        } else {
          this.poiSheet.POISheetLocale.push({
            Title: itFormValue.title,
            Description: itFormValue.description,
            LanguageCode: 'IT'
          });
        }
      }

    }

    if (enFormValue.title || enFormValue.description) {
      if (this.poiSheet) {
        if (this.poiSheet.POISheetLocale.length) {
          const currentLocale = this.poiSheet.POISheetLocale.filter(locale => locale.LanguageCode === 'EN');
          if (currentLocale.length) {
            const indexLocale = this.poiSheet.POISheetLocale.findIndex(locale => locale.LanguageCode === 'EN');
            this.poiSheet.POISheetLocale[indexLocale].Title = enFormValue.title;
            this.poiSheet.POISheetLocale[indexLocale].Description = enFormValue.description;
          } else {
            this.poiSheet.POISheetLocale.push({
              Title: enFormValue.title,
              Description: enFormValue.description,
              LanguageCode: 'EN'
            });
          }
        } else {
          this.poiSheet.POISheetLocale.push({
            Title: enFormValue.title,
            Description: enFormValue.description,
            LanguageCode: 'EN'
          });
        }
      }

    }


    if (this.poiAttachmentsImage.length) {
      this.poiAttachements = this.poiAttachmentsImage.concat(this.poiAttachements);
    }

    if (this.poiAttachmentsAudio.length) {
      this.poiAttachements = this.poiAttachmentsAudio.concat(this.poiAttachements);
    }

    if (this.poiAttachmentsVideo.length) {
      this.poiAttachements = this.poiAttachmentsVideo.concat(this.poiAttachements);
    }

    if (this.poiAttachmentsAr.length) {
      this.poiAttachements = this.poiAttachmentsAr.concat(this.poiAttachements);
    }

    if (this.poiSheet) {
      this.poiSheet.Attachments = this.poiAttachements.filter(attachment => attachment.ID !== 0);
    }



    const poi: Ipoi = {
      ID: this.currentPoi.ID,
      CompleteAddress: itFormValue.CompleteAddress ? itFormValue.CompleteAddress : enFormValue.CompleteAddress,
      Longitude: itFormValue.Longitude,
      Latitude: itFormValue.Latitude,
      POIType: itFormValue.POIType,
      Locales: [
        {Address: itFormValue.CompleteAddress, LanguageCode: 'it-IT'},
        {Address: enFormValue.CompleteAddress, LanguageCode: 'en-EN'},
      ],
      Sheet: this.poiSheet
    };

    const newPoiAttachements = this.poiAttachements.filter(attachment => attachment.ID === 0);
    this.editPoi(poi, newPoiAttachements);
  }

  editPoi(poi: Ipoi, poiAttachments: IPoiAttachment[]) {
    this.isLoading = true;
    if (poiAttachments && poiAttachments.length) {
      this.poiService.editPoi(poi).pipe(
        switchMap(editPoi => {
          return from(poiAttachments).pipe(
            mergeMap(attachment =>   {
              if (attachment.MediaRef.MediaType === 'Image') {
                return this.poiService.insertPoiAttachment(attachment, editPoi.ID.toString(), this.fileMap.get(attachment.MediaRef.FileName));
              }
              if (attachment.MediaRef.MediaType === 'Audio') {
                return this.poiService.insertPoiAttachment(attachment, editPoi.ID.toString(), this.fileMap.get(attachment.MediaRef.FileName));
              }
              if (attachment.MediaRef.MediaType === 'Video') {
                return this.poiService.insertPoiAttachment(attachment, editPoi.ID.toString(), this.fileMap.get(attachment.MediaRef.FileName));
              }
              if (attachment.MediaRef.MediaType === 'AR') {
                return this.poiService.insertPoiAttachment(attachment, editPoi.ID.toString(), this.fileMap.get(attachment.MediaRef.FileName));
              }
            })
          );
        })
      ).subscribe(currentPoi => {
        this.isLoading = false;
        this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
          data: this.translateService.instant('poi-edit'),
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        this.dialogRef.close(currentPoi);
      }, error => {
        this.isLoading = false;
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Ops, si è verificato un errore imprevisto',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        console.log(error);
      });
    } else {
      this.poiService.editPoi(poi).subscribe(currentPoi => {
        this.isLoading = false;
        this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
          data: this.translateService.instant('poi-edit'),
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        this.dialogRef.close(currentPoi);
      }, error => {
        this.isLoading = false;
        this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
          data: 'Ops, si è verificato un errore imprevisto',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        console.log(error);
      });
    }

  }
}
