import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../../service/user/user.service';
import { IUser } from '../../../../interface/iuser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {

  editForm: FormGroup;
  currentUser: IUser;
  isLoading: boolean;

  constructor(
    private dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.currentUser = data;
  }

  ngOnInit() {
    this.checkEditForm();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  checkEditForm() {
    if (this.currentUser) {
      this.editForm = this.formBuilder.group({
        Username: [this.currentUser.Username, Validators.required],
        Role: [this.currentUser.Role, Validators.required],
        Disabled: [this.currentUser.Disabled, Validators.required],
        Email: [this.currentUser.Email, Validators.required],
        PasswordHash: [this.currentUser.PasswordHash],
        PhoneNumber: [this.currentUser.PhoneNumber],
        Notifies: [[]],
        Feedback: [[]]
      });
    }
  }

  onSubmit() {
    if (this.editForm.invalid) {
      return;
    }

    const user: IUser = {
      ID: this.currentUser.ID,
      Username: this.editForm.value.Username,
      Role: this.editForm.value.Role,
      Disabled: this.editForm.value.Disabled,
      Email: this.editForm.value.Email,
      PasswordHash: this.editForm.value.PasswordHash,
      PhoneNumber: this.editForm.value.PhoneNumber,
      Notifies: this.editForm.value.Notifies,
      Feedback: this.editForm.value.Feedback
    };

    if (user.PasswordHash !== null) {
      this.changePassword(user);
    }

    this.editUser(user);
  }

  changePassword(user: IUser) {
    const passwordObj = { Username: user.Username, Password: user.PasswordHash };
    this.userService.changePassword(passwordObj).subscribe(res => {
    }, error => {
        console.log(error);
    });
  }


  editUser(user: IUser) {
    this.isLoading = true;
    this.userService.editUser(user).subscribe(() => {
      this.isLoading = false;
      this.translateService.get('UserEdit_200.title').subscribe(message => {
        this.matSnackBar.openFromComponent(SnackbarSuccessComponent, {
          data: message,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      })
      this.closeDialog();
    }, () => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackbarErrorComponent, {
        data: 'Ops, si è verificato un errore imprevisto',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    });
  }


}
