import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { ProfileDialogComponent } from '../../dialog/profile/profile-dialog/profile-dialog.component';
import { EditProfileDialogComponent } from '../../dialog/profile/edit-profile-dialog/edit-profile-dialog.component';
import { IUser } from '../../../interface/iuser';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {
    translate.addLangs(['gb', 'it']);
    translate.setDefaultLang('gb');

    const browserLang = translate.getBrowserLang();
    const currentLanguage = sessionStorage.getItem('language');

    if (currentLanguage) {
      translate.use(currentLanguage);
    } else {
      translate.use(browserLang.match(/gb|it/) ? browserLang : 'gb');
    }
  }


  ngOnInit() {}

  // Method that change language.
  switchLanguage(language: string) {
    this.translate.use(language);
    sessionStorage.setItem('language', language);
  }

  // Methot that signOut and deleted cookie from browser.
  signOut() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('idUser');
    this.router.navigate(['sign-in']);
  }

  opentProfileDialog() {
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => console.log('Closed'));
  }


  openEditProfileDialog() {
    const dialogRef = this.dialog.open(EditProfileDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => console.log('Closed'));
  }

}
