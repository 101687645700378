import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';


@Component({
  selector: 'app-language-route',
  templateUrl: './language-route.component.html',
  styleUrls: ['./language-route.component.scss']
})
export class LanguageRouteComponent implements OnInit {
  @Output() locales = new EventEmitter<any>();
  languageForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.checkLanguageForm();
  }


  checkLanguageForm() {
    this.languageForm = this.formBuilder.group({
      language: [null, Validators.required],
      locales: this.formBuilder.array([])
    });
  }

  get f() { return this.languageForm.controls; }

  get localesForm() { return this.languageForm.get('locales') as FormArray; }



  onChangeLanguages(language: string) {
    const locales = this.languageForm.get('locales') as FormArray;
    const locale = this.formBuilder.group({
      ID: [0],
      LanguageCode: [language],
      Name: [null, Validators.required],
      Description: [null, Validators.required]
    });

    locales.push(locale);

  }

  deleteLocale(index: number) {
    const locales = this.languageForm.get('locales') as FormArray;
    locales.removeAt(index);
  }

  onSubmit() {
    if (this.languageForm.invalid) {
      return;
    }


    this.locales.emit(this.languageForm.value);
  }

}
